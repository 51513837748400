<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">あなたが司書課程を履修した際の気持ちについて、もっとも近いものをお選びください。<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<v-select
					v-model="form.motivation"
					label="司書資格取得の意欲"
					:items="motivations"
					item-text="text"
					item-value="value"
					:rules="[rules.required]"
				>
					<template v-slot:item="{ item, attrs, on }">
						<v-list-item v-bind="attrs" v-on="on">
							<v-list-item-title class="text-wrap">
								{{ item.text }}
							</v-list-item-title>
						</v-list-item>
					</template>
				</v-select>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurvey',
	data () {
		return {
			scaleitemorders: [],
			form: {
				motivation: null,
				certificate_year: null
			},
			rules: {
				required: value => !!value || '必須'
			},
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりであった", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりであった", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれなかった", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりなかった", value: 4 },
			],
			certificate_years: [
				{ text: "1970年代", value: 1 },
				{ text: "1980年代", value: 2 },
				{ text: "1990年代", value: 3 },
				{ text: "2000年代", value: 4 },
				{ text: "2010年代", value: 5 },
				{ text: "2020年代", value: 6 },
			]
		}
	},
	async created () {
		this.setLoading(true)
		this.clearScaleScore()
		const scaleId = this.test.scales[0].id
		await this.getScale(scaleId).then(() => {
			this.clearScaleItemAnswers()
			this.clearAnsweredScales()
			this.setScaleItemNotAnswered(this.scale.scaleitemorders)
			this.setLoading(false)
			this.scaleitemorders = this.scale.scaleitemorders
			this.startAt = Date.now()
		}).catch(err => {
			this.clearScale()
			this.error = true
			this.setLoading(false)
			console.error(err)
		})
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			scale: 'stateScale',
			scaleItemAnswered: 'stateScaleItemAnswered',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			answeredScales: 'stateAnsweredScales',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		}
	}
}
</script>