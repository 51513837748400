import { render, staticRenderFns } from "./Q3.vue?vue&type=template&id=4c7ad11a&scoped=true&"
import script from "./Q3.vue?vue&type=script&lang=js&"
export * from "./Q3.vue?vue&type=script&lang=js&"
import style0 from "./Q3.vue?vue&type=style&index=0&id=4c7ad11a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7ad11a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCol,VListItem,VListItemTitle,VRow,VSelect})
